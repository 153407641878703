import { LanguageDetectorModule } from 'i18next';

export const pathPartLangDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => undefined,
  cacheUserLanguage: () => undefined,
  detect: () => {
    const path = window.location.pathname.substring(1);
    const lang = path.split('/', 2)[0];

    // Simple check to prevent some invalid language tags.
    if (lang.length === 2) {
      console.debug(`Detected language: ${lang}`);
      return lang;
    }
  },
};
