import React from 'react';
import { Header } from './Header';

export interface Props {
  className?: string;
}

export const MinimalLayout: React.FC<Props> = ({ children, className }) => (
  <div className="minimal-layout">
    <Header />
    <main className={className}>{children}</main>
  </div>
);
