import create, { StoreApi } from 'zustand';

export interface ContactStore {
  contact?: Contact;
  setContact: (contact: Contact) => void;
  loading: boolean;
  setNoContact: () => void;
  assertContact: () => Contact;
}

export interface Contact {
  email: string;
  firstName: string;
  lastName?: string;
  phoneNumber?: string;
  phonePrefix?: string;
  id?: string;
  active: boolean;
  isAwaitingConfirmation?: number;
}

export const useContactStore = create<ContactStore>((set, get) => ({
  loading: true,
  setContact: (contact: Contact) => {
    set({ contact, loading: false });
  },
  setNoContact: () => {
    set({ contact: undefined, loading: false });
  },
  assertContact: () => {
    const contact = get().contact;
    if (!contact) {
      throw new Error(
        'Cannot assert contact when no contact is present in the store.'
      );
    }
    return contact;
  },
}));

export const contactStore = (): ContactStoreApi => useContactStore;
export type ContactStoreApi = StoreApi<ContactStore>;
