export interface RequestOptions {
  params?: Record<string, string>;
  headers?: Record<string, string>;
}

export class ApiError extends Error {
  public constructor(
    public readonly status: number,
    public readonly body?: object
  ) {
    super();
  }
}

export type ApiOptionsInit = Partial<ApiOptions>;

export type BodyType = 'json' | 'urlencoded';

interface ApiOptions {
  includeCredentials: boolean;
  bodyType: BodyType;
}

export class ApiService {
  private readonly apiOptions: ApiOptions;

  public constructor(
    private readonly baseUrl: string,
    apiOptions?: ApiOptionsInit
  ) {
    // Initialise the API options so that all values are set.
    this.apiOptions = {
      includeCredentials: true,
      bodyType: 'json',
      ...apiOptions,
    };
  }

  protected static async handleResponse<T>(
    resPromise: Promise<Response>
  ): Promise<T> {
    const response = await resPromise;
    let responseBody: unknown;

    if (response.headers.get('Content-Type') === 'application/pdf') {
      responseBody = response.blob();
      return responseBody as T;
    }

    if (response.status !== 204) {
      responseBody = await ApiService.parseResponseJson(response);
    }

    if (response.status >= 400) {
      throw new ApiError(response.status, responseBody as object);
    }

    return responseBody as T;
  }

  private static async parseResponseJson(response: Response): Promise<unknown> {
    const responseText = await response.text();
    try {
      return JSON.parse(responseText);
    } catch (e) {
      throw new Error(`Invalid JSON in response body:\n${responseText}`);
    }
  }

  private static xdebugParam(): Record<string, string> | undefined {
    if (process.env.NODE_ENV === 'development') {
      // Check if the XDEBUG_SESSION cookie is present.
      const xdebugCookie = document.cookie
        .split(';')
        .map((c) => c.trim())
        .find((c) => c.startsWith('XDEBUG_SESSION='));

      if (xdebugCookie) {
        const [name, value] = xdebugCookie.split('=');
        return { [name]: value };
      }
    }
  }

  private createRequestBody(body: unknown): BodyInit | undefined {
    if (!body) {
      return undefined;
    }

    switch (this.apiOptions.bodyType) {
      case 'json':
        return JSON.stringify(body);
      case 'urlencoded':
        return new URLSearchParams(body as Record<string, string>);
      default:
        throw new Error(
          `Unknown request body type: ${this.apiOptions.bodyType}`
        );
    }
  }

  private getUrl(path: string, params?: Record<string, string>): string {
    const allParams = {
      ...(params || {}),
      ...ApiService.xdebugParam(),
    };

    const paramString =
      Object.keys(allParams).length > 0
        ? `?${new URLSearchParams(allParams)}`
        : '';
    return `${this.baseUrl}${path}${paramString}`;
  }

  protected async getHeaders(): Promise<Record<string, string>> {
    return {
      Accept: 'application/json',
    };
  }

  protected async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    path: string,
    body?: unknown,
    options?: RequestOptions
  ): Promise<T> {
    const headers = {
      ...(await this.getHeaders()),
      ...options?.headers,
    };

    if (body) {
      headers['Content-Type'] =
        this.apiOptions.bodyType === 'json'
          ? 'application/json'
          : 'application/x-www-form-urlencoded; charset=UTF-8';
    }

    return ApiService.handleResponse(
      fetch(this.getUrl(path, options?.params), {
        credentials: this.apiOptions.includeCredentials
          ? 'include'
          : 'same-origin',
        method,
        headers,
        body: this.createRequestBody(body),
      })
    );
  }

  public jsonGet<T>(path: string, options?: RequestOptions): Promise<T> {
    return this.request('GET', path, undefined, options);
  }

  public jsonPost<T, S>(
    path: string,
    body: T,
    options?: RequestOptions
  ): Promise<S> {
    return this.request<S>('POST', path, body, options);
  }

  public jsonPut<T, S>(
    path: string,
    body: T,
    options?: RequestOptions
  ): Promise<S> {
    return this.request<S>('PUT', path, body, options);
  }
}
