import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/index.scss';
import './i18n/config';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Injector } from './services/Injector';
import { InjectionProvider } from './hooks/useService';
import { getConfig } from './config';
import { LoadingPage } from './pages/LoadingPage';

const config = getConfig();

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.environment,
});

const injector = new Injector();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <InjectionProvider value={injector}>
        <Suspense fallback={<LoadingPage />}>
          <App />
        </Suspense>
      </InjectionProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('safe-deal-checkout')
);
