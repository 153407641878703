import React from 'react';
import { Icon } from '../components/Icon';
import { MinimalLayout } from '../layouts/MinimalLayout';

export const LoadingPage: React.FC = () => (
  <MinimalLayout className="loading-page">
    <Icon icon="spinner-third" spin />
    <p>Loading your checkout page...</p>
  </MinimalLayout>
);
