import { Injector } from './Injector';
import { AccountService, JwtData } from './AccountService';
import { JwtResponse, WebService } from './WebService';
import { AuthStore } from '../store/auth';
import { ContactStore } from '../store/contact';
import { deleteCookie } from '../utils/cookie';

export class NoJwtDataError extends Error {}

export class AuthService {
  public static readonly NAME = 'AuthService';

  private readonly accountService: AccountService;
  private readonly webService: WebService;

  public constructor(injector: Injector) {
    this.accountService = injector.resolve(AccountService);
    this.webService = injector.resolve(WebService);
  }

  public async createAnonAccount(
    auth: AuthStore,
    contact: ContactStore
  ): Promise<boolean> {
    let anonToken: JwtData;
    try {
      console.debug('Creating anonymous session.');
      deleteCookie('tokenExpiryTime');
      anonToken = await this.accountService.createAnonToken();
    } catch (e) {
      console.error('Error while creating anonymous session.');
      console.error(e);
      return false;
    }

    auth.setTokens(
      anonToken.access_token,
      anonToken.refresh_token,
      anonToken.expires_in
    );
    contact.setNoContact();

    return true;
  }

  public async setUserSession(
    auth: AuthStore,
    contact: ContactStore
  ): Promise<boolean> {
    let jwtResponse: JwtResponse | undefined;
    try {
      jwtResponse = await this.webService.tokenFromSession();
    } catch (e) {
      console.error('Error while getting session data from web.');
      console.error(e);
      return false;
    }

    // No JWT data at all
    if (!jwtResponse) {
      throw new NoJwtDataError('Response does not contain any JWT data.');
    }

    // This is a known bug in web:
    // in some scenarios getting the session data will return an authenticated session,
    // but won't actually return JWT data.
    if (jwtResponse.authenticated && !jwtResponse.jwtData?.access_token) {
      throw new NoJwtDataError(
        'Authenticated session does not contain JWT data.'
      );
    }

    const isValidated = this.validateAndStoreJwtData(
      jwtResponse,
      auth,
      contact
    );
    if (!isValidated) {
      try {
        return await this.createAnonAccount(auth, contact);
      } catch (e) {
        console.error('Error while creating anonymous account.');
        console.error(e);
        return false;
      }
    }
    return true;
  }

  private validateAndStoreJwtData(
    jwtResponse: JwtResponse,
    auth: AuthStore,
    contact: ContactStore
  ): boolean {
    if (!jwtResponse?.jwtData?.access_token) {
      return false;
    }
    auth.setTokens(
      jwtResponse.jwtData.access_token,
      jwtResponse.jwtData.refresh_token,
      jwtResponse.jwtData.expires_in
    );
    if (jwtResponse.id && jwtResponse.name && jwtResponse.email) {
      const names = jwtResponse.name.split(' ');
      console.debug('Storing contact info from session.');
      contact.setContact({
        active: jwtResponse.authenticated,
        email: jwtResponse.email,
        firstName: names[0],
        lastName: names[1] || '',
        id: jwtResponse.id,
      });
    } else {
      console.debug('No contact info returned from session.');
      contact.setNoContact();
    }
    return true;
  }
}
