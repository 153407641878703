export type ClassName = string | undefined | false;

/**
 * Construct a className string from a list of class name values,
 * filtering out any falsy values.
 *
 * For example:
 *
 * ```
 * classNames('one', false, 'two', undefined);
 * // Returns: 'one two'
 * ```
 *
 * This also makes it simple to apply conditional class names:
 *
 * ```
 * classNames('thing', active && 'active');
 * // Returns 'thing active' if active is truthy, or 'thing' otherwise.
 * ```
 *
 * @param classList The class names to concatenate.
 */
export function classNames(...classList: ClassName[]): string {
  return classList.filter((c): boolean => !!c).join(' ');
}
