import { createContext, useContext } from 'react';
import { Injector, Provider } from '../services/Injector';

const Context = createContext<Injector | undefined>(undefined);

export const InjectionProvider = Context.Provider;

/**
 * Get a service from the {@link Injector}, for use in components or hooks.
 *
 * For example:
 *
 * ```
 * const Example: React.FC = () => {
 *   const esb = useService(EsbService);
 *   // Do stuff with esb.
 *
 *   return (...);
 * };
 * ```
 *
 * @param provider The service class to use.
 */
export function useService<T>(provider: Provider<T>): T {
  const injector = useContext(Context);
  if (!injector) {
    throw new Error('Wrap this component in an InjectionProvider');
  }
  return injector.resolve(provider);
}
