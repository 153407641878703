import { getConfig } from '../config';

export interface Cookie {
  name: string;
  value: string;
  days?: number;
  domain?: string;
}

const domain = getConfig().web.cookiesDomain;

export function getCookie(name: string) {
  const cookieString = document.cookie
    .split(';')
    .map((c) => c.trim())
    .find((c) => c.startsWith(name));
  return cookieString ? cookieString.split('=').pop() : '';
}

export function setCookie(data: Cookie) {
  const date = new Date();
  date.setTime(date.getTime() + (data.days || 365) * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${data.name}=${data.value}; ${expires}; domain=${
    data.domain || domain
  }; path=/`;
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/`;
}
