import i18n from 'i18next';
// This is the only place we're allowed to import 'react-i18next', other places should import from this script.
// eslint-disable-next-line no-restricted-imports
import {
  initReactI18next,
  Trans as i18nTrans,
  useTranslation as useTranslationI18next,
} from 'react-i18next';
import { Leaves } from '../utils/Leaves';
import { pathPartLangDetector } from './pathPartLangDetector';
import enJson from './content/en.json';
import nlJson from './content/nl.json';
import arJson from './content/ar.json';
import bgJson from './content/bg.json';
import daJson from './content/da.json';
import deJson from './content/de.json';
import esJson from './content/es.json';
import fiJson from './content/fi.json';
import frJson from './content/fr.json';
import hrJson from './content/hr.json';
import itJson from './content/it.json';
import nnJson from './content/nn.json';
import plJson from './content/pl.json';
import ptJson from './content/pt.json';
import roJson from './content/ro.json';
import ruJson from './content/ru.json';
import svJson from './content/sv.json';

type Languages =
  | 'en'
  | 'nl'
  | 'ar'
  | 'bg'
  | 'da'
  | 'de'
  | 'es'
  | 'fi'
  | 'fr'
  | 'hr'
  | 'it'
  | 'nn'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sv';

type Resources = {
  [L in Languages]: { translation: typeof enJson };
};

export const resources: Resources = {
  en: { translation: enJson },
  nl: { translation: nlJson },
  ar: { translation: arJson },
  bg: { translation: bgJson },
  da: { translation: daJson },
  de: { translation: deJson },
  es: { translation: esJson },
  fi: { translation: fiJson },
  fr: { translation: frJson },
  hr: { translation: hrJson },
  it: { translation: itJson },
  nn: { translation: nnJson },
  pl: { translation: plJson },
  pt: { translation: ptJson },
  ro: { translation: roJson },
  ru: { translation: ruJson },
  sv: { translation: svJson },
};

i18n
  .use(pathPartLangDetector)
  .use(initReactI18next)
  .init({
    react: {
      transSupportBasicHtmlNodes: true,
    },
    fallbackLng: 'en',
    interpolation: {
      // Not needed, React escapes by default.
      escapeValue: false,
    },
    resources,
  });

type Translations = typeof resources.en.translation;

export type TranslationKey = Leaves<Translations>;

type TFunction = (key: TranslationKey) => string;

export function useTranslation(): {
  t: TFunction;
  language: string;
  i18n: typeof i18n;
} {
  const i18next = useTranslationI18next();

  return {
    t: i18next.t,
    language: i18next.i18n.language,
    i18n: i18next.i18n,
  };
}

export const Trans = i18nTrans;
