import { ApiService } from './ApiService';
import { getConfig } from '../config';
import { ClosingHoursProps } from '../layouts/Header';
import { JwtData } from './AccountService';

export interface JwtResponse {
  jwtData: JwtData;
  authenticated: boolean;
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export class WebService {
  public static readonly NAME = 'WebService';

  private readonly api: ApiService;

  public constructor() {
    this.api = new ApiService(getConfig().web.url, {
      includeCredentials: true,
      bodyType: 'urlencoded',
    });
  }

  public async tokenFromSession(): Promise<JwtResponse> {
    return this.api.jsonGet('/get-jwt-from-session');
  }

  public async getClosingHours(
    vehicleTypeId?: number
  ): Promise<ClosingHoursProps> {
    const options = {
      params: { vehicleTypeId: String(vehicleTypeId) },
    };
    const path = '/website/index/get-opening-hours-json';
    return vehicleTypeId
      ? this.api.jsonGet(path, options)
      : this.api.jsonGet(path);
  }

  public proceedToPaymentRequest(
    downPaymentMethodId: number,
    paymentType: string,
    ticketId: string
  ) {
    setTimeout(() => {
      window.open(
        `${
          getConfig().web.url
        }/payment/world/request?downPaymentMethod=${downPaymentMethodId}&paymentType=${paymentType}&offerId=${ticketId}`,
        '_self'
      );
    }, 3000);
  }

  public proceedToBuyerOffer(ticketId: string) {
    setTimeout(() => {
      window.open(`${getConfig().web.url}/offer/buyer/${ticketId}`, '_self');
    }, 3000);
  }
}
