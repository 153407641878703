import React, { useEffect, useState } from 'react';
import { useTranslation } from '../i18n/config';
import { WebService } from '../services/WebService';
import { useService } from '../hooks/useService';
import { useMountedState } from '../hooks/useMountedState';
import { Icon } from '../components/Icon';
import { useTicketStore } from '../store/ticket';
import { getConfig } from '../config';

export interface ClosingHoursProps {
  isClosed: boolean;
  closingHourToday: string;
  hoursPostfix: string;
  timezone: string;
  phone: string;
}

export const Header: React.FC = () => {
  const { language } = useTranslation();
  const url = `${getConfig().web.url}/${language}`;
  return (
    <header className="header">
      <div className="header-content">
        <a className="header-brand" href={url}>
          <small>trusted trade platform</small>
        </a>
        <AvailableHours />
      </div>
    </header>
  );
};

export const AvailableHours: React.FC = () => {
  const webService = useService(WebService);
  const [closingHours, setClosingHours] = useState<ClosingHoursProps>();
  const mounted = useMountedState();
  const ticket = useTicketStore((s) => s.ticket);

  useEffect(() => {
    const updateClosingHours = async () => {
      let response;
      try {
        response = await webService.getClosingHours(
          ticket?.vehicle?.vehicleTypeId
        );
      } catch (e) {
        console.error(`Error while getting closing hours: ${e}`);
        return;
      }
      if (mounted.current) {
        setClosingHours(response);
      }
    };

    updateClosingHours();
    const interval = setInterval(() => updateClosingHours(), 60000 * 5);

    return () => {
      clearInterval(interval);
    };
  }, [webService, mounted, ticket?.vehicle?.vehicleTypeId]);

  return (
    <div className="available-hours">
      {closingHours?.isClosed || closingHours?.closingHourToday === '' ? (
        <WeClosed />
      ) : (
        <WeOpened
          phone={closingHours?.phone}
          closingHourToday={closingHours?.closingHourToday}
          hoursPostfix={closingHours?.hoursPostfix}
        />
      )}
    </div>
  );
};

interface WeOpenedProps {
  phone?: string;
  hoursPostfix?: string;
  closingHourToday?: string;
}

export const WeOpened: React.FC<WeOpenedProps> = ({
  phone,
  hoursPostfix,
  closingHourToday,
}) => {
  const { t } = useTranslation();
  const telAttribute = { href: 'tel:' + phone };
  return (
    <div className="contact-info d-flex">
      {phone ? (
        <div className="d-flex">
          <div className="tel-icon">
            <Icon icon="phone" />
          </div>
          <div>
            <div className="tel-number">
              <a {...telAttribute}>{phone}</a>
            </div>
            <div className="closing-hour">
              {t('header.available-until')} {closingHourToday} {hoursPostfix}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export const WeClosed: React.FC = () => {
  const { t } = useTranslation();
  return <div className="contact-info d-flex">{t('header.we-are-closed')}</div>;
};
