import React, { UIEvent } from 'react';
import { classNames } from '../utils/classNames';

export interface Props {
  icon: string;
  iconStyle?: IconStyle;
  'aria-label'?: string;
  spin?: boolean;
  className?: string;
  // Use UIEvent here, since it can also be a keyboard event.
  onClick?: (e: UIEvent<HTMLElement>) => void;
}

export type IconStyle = 'solid' | 'regular' | 'light';

export const Icon: React.FC<Props> = ({
  icon,
  iconStyle = 'solid',
  spin,
  className,
  ...props
}) => (
  <i
    {...props}
    className={classNames(
      `icon fa-${iconStyle} fa-${icon}`,
      spin && 'fa-spin',
      props.onClick && 'clickable',
      className
    )}
    tabIndex={props.onClick ? 0 : undefined}
    onKeyDown={(e) => {
      if (props.onClick && (e.key === ' ' || e.key === 'Enter')) {
        e.preventDefault();
        props.onClick(e);
      }
    }}
    role={props.onClick ? 'button' : undefined}
  />
);
