import { ApiService } from './ApiService';
import { getConfig } from '../config';

export interface ContactLogin {
  email: string;
  password: string;
  username?: string;
}

export interface JwtData {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  expiry_time?: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
}

export class AccountService {
  public static readonly NAME = 'AccountService';

  private readonly api: ApiService;

  public constructor() {
    this.api = new ApiService(getConfig().account.url);
  }

  public createAnonToken(): Promise<JwtData> {
    return this.api.jsonPost('/v1/create', {});
  }

  public refresh(refreshToken: string): Promise<JwtData> {
    return this.api.jsonPost('/v1/refresh', { refresh_token: refreshToken });
  }

  public login(contactLogin: ContactLogin): Promise<JwtData> {
    return this.api.jsonPost('/v1/login', contactLogin);
  }
}
